<template>
  <el-main class="rh-table">
    <h1><i class="el-icon-setting" /> Options de faculté</h1>
    <p>
      Les facultés figurant dans les formations prégraduées ou les thèses ne
      peuvent pas êtres supprimées.
    </p>
    <app-table
      tablenom="facultes"
      :cols="colonnes"
      :items="facultes"
      :loadstatus="facultesLoadStatus"
      :deletestatus="faculteDeleteStatus"
      :formroutes="facFormRoutes"
      :actions="facActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      facFormRoutes: {
        edit: { route: "faculteedit", params: { idfac: "id" } },
        create: { route: "facultecreate" },
      },
    };
  },
  computed: {
    facultesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fac.facultesLoadStatus;
    },
    faculteDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fac.faculteDeleteStatus;
    },
    facultes() {
      return this.$store.state.fac.facultes;
    },
    colonnes() {
      return this.$store.state.fac.colonnes;
    },
    facActions() {
      return this.$store.state.fac.actions;
    },
  },
};
</script>
